import React, { useEffect, useCallback, useState, useRef } from 'react';

import { summarize } from '../../Utilitites/chatBotUtils'; // Assuming you have a chatBotUtils.js file

import { createMod, fetchMods, updateMod } from '../../../DBService';
import Conversation from './Conversation';
import { grey } from '@mui/material/colors';

import TimestampFormatter from './Appointments/TimestampFormatter';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const ChatDisplay = () => {
  const [dbChange, setDbChange] = useState(null);
  const [selectedConversatonIndex, setSelectedConversatonIndex] = useState(0);
  const [chats, setChats] = useState([]); // Assume chats is an array
  const [botState, setBotState] = useState({
    assistant: null,
    thread: null,
    openai: null,
    waiting: false,
  });

  const [appointment, setAppointment] = useState({});
  const [appointmentError, setAppointmentError] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [historyVisible, setHistoryVisible] = useState(true);
  const historyEndRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAppointmentLoading, setIsAppointmentLoading] = useState(false);
  const audioRef = useRef(null);
  const [shouldPlayDing, setShouldPlayDing] = useState(false);


  
  const fetchMod = async () => {
    try {
      const dbmodData = await fetchMods('insituChat'); // Changed to the correct function call
      if (dbmodData.length !== conversations.length) {
        setIsLoading(true);
        
        setChats(dbmodData.reverse());
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error loading dbMods:', error);
    } finally {
    }
  };

  useEffect(() => {
    fetchMod();

    const intervalId = setInterval(fetchMod, 5000000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);

  }, []);

  useEffect(() => {
    const transformedConversations = chats.map((conversation) =>
      Object.entries(conversation).flatMap(([key, value]) => {
        if (key === '_id' || !value.message) return [];
        return {
          type: value.type,
          message: value.message,
        };
      })
    );
    setConversations(transformedConversations);
  }, [chats]);

  const handleSelectConversation = (index) => () => {
    setSelectedConversatonIndex(index);
    setAppointment(null);
    setAppointmentError(null);
  };
  

    const convertToAppointment = async (chat) => {
      setIsAppointmentLoading(true);
      let appointment = await summarize(chat);
      
      
      if (appointment?.appointmentTimestamp) {
        console.log('chatID  === ' + chats[selectedConversatonIndex]._id.$oid);
        appointment.chatId = chats[selectedConversatonIndex]._id.$oid;
        
        setAppointment(appointment)

        
        
      }
      else {
        setAppointmentError('Not enough info to set an appointment');
      }
      console.log('doneloadingapp');
      setIsAppointmentLoading(false);
      
    };
   


  useEffect(() => {
    if (conversations.length) {
      const lastRef = historyEndRefs.current[conversations.length - 1];
      if (lastRef) {
        lastRef.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [conversations]);

  const handleSummarize = useCallback(async (index) => {
    await summarize(conversations[index], botState, setBotState);
  }, [botState, conversations]);

  const modInfo = {
    colors: {
      primary: '#007bff',
    },
  };
  const confirmAppointment = async (chat) => {
    const modId = await createMod(appointment, 'in-situ-appointments');
    await updateMod(chat.convoId, modId.insertedId.$oid, 'appointmentId', 'insituChat')
    
  };

  return isLoading ? 
     (<p>Loading...</p>)
  :
   (
    <div>
      
      <div style={{ display: 'flex', justifyContent: 'center'}}>

        <div style={{ maxWidth: '400px', width: '100vw', height: '300px' }}>

          <Conversation
            conversation={conversations[selectedConversatonIndex]}
            historyVisible={historyVisible}
            modInfo={modInfo}
            chats={chats}
            conversationIndex={selectedConversatonIndex}
          />
        </div>
          {
            /*
            appointment || chats[selectedConversatonIndex].appointment ?
              (
                <div style={{ width: '50vw', minWidth: '300px', height: '300px' }}>
                  <button onClick={confirmAppointment}>Confirm appointment</button>
                  <Appointment data={appointment} />
                </div>
              ):
              isAppointmentLoading ?
                (<p>Loading</p>) :
                  appointmentError ?
                    (<p>{appointmentError}</p>):
                    (
                      <a onClick={ () => convertToAppointment(chats[selectedConversatonIndex])}>

                      <div style={{ width: '50vw', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {'=>'}
                      </div>
                      </a>
                    )
                    */
        }
      </div>
      <br />
      <br />
      <div style={{ maxWidth: '100vw', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      

        {conversations && conversations.map((conversation, conversationIndex) => (
          <div 
          style={{
            width: '300px',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            backgroundColor: conversationIndex === selectedConversatonIndex ? '#c2bcf7' : 'white',
            borderRadius: '0 25px'
            }}
            key={conversations.length - conversationIndex - 1} onClick={handleSelectConversation(conversationIndex)}>
            <h2>Conversation { conversations.length - conversationIndex} </h2>
            <h3><TimestampFormatter timestamp={chats[conversationIndex]['timestamp']} /></h3>
            {
              conversationIndex === selectedConversatonIndex ?
              (
                
                <p><FontAwesomeIcon icon={faEye} /></p>
                ):
                <>
                <br />
                  <FontAwesomeIcon icon={faEye} />
                  <h3>View</h3>
                </>
              }
          </div>
        ))}
      </div>
        
         

      
    </div>
);
};

export default ChatDisplay;
