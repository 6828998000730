import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { SHA256 } from 'crypto-js';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  const navigate = useNavigate(); // Initialize useNavigate outside of the login function

  const login = async (username, password) => {
    try {
      // Hash the password
      const hashedPassword = SHA256(password).toString();
      // API Call with username and hashed password
      const response = await fetch(API_URL + '/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, 'hash': hashedPassword }),
      });

      const data = await response.json();
      if (response.ok && data.success) {
        localStorage.setItem('user', data.user.username)

        // Assuming the API returns the user's data on successful login
        setUser(data.user);
        // Redirect the user to /home on successful login
        navigate('/');
      } else {
        //
        // Handle errors (e.g., show a message to the user)
        console.error(data.error);
      }
    } catch (error) {
      console.error('Login error:', error);
      navigate('/');
    }
    // Redirect to the '/' route if not authenticated
  };

  const logout = () => {
    setUser(null); // Clears the current user state
    localStorage.removeItem('user'); // Removes user data from localStorage to clear the session
    navigate('/login'); // Redirects to the login page or any other page you deem appropriate after logout
  };
  

  const value = { user, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
