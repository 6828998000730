import React, { useState } from 'react';
import './Login.css';

import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext.js';

const Login = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false); // State for managing loading state
    const [error, setError] = useState(''); // State for managing error messages

    // Basic validation for username and password
    const validateForm = () => {
        if (!username || !password) {
            setError('Username and password are required');
            return false;
        }
        setError('');
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return; // Perform validation

        try {
            setLoading(true); // Set loading state to true before login attempt
            await login(username, password); // Attempt to log in
            navigate('/'); // Redirect to dashboard upon successful login
        } catch (error) {
            setError('Failed to log in'); // Set error message if login fails
        } finally {
            setLoading(false); // Reset loading state regardless of login outcome
        }
    };

    return (
        <div style={{width: '100vw', maxWidth: '500px'}}>
            <h2>Login</h2>
            {error && <div className="error-message">{error}</div>} {/* Display error message if any */}
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label>Password</label>
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" style={{ padding: '17.5px 15px',
        margin: '20px 15px',
        fontSize: '16px',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer', }} disabled={loading}>
                    {loading ? 'Logging in...' : 'Login'}
                </button>
                <button onClick={() => navigate('/')} style={{ padding: '17.5px 15px',
        margin: '20px 15px',
        fontSize: '16px',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer', }} disabled={loading}>
                    Cancel
                </button>
            </form>
        </div>
    );
};

export default Login;
