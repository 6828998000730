import React from 'react';

// Import your custom components


import { treadmill } from 'ldrs'
import Header1 from './Headers/Header1';
import Cover1 from './Cover1';
import Service1 from './Services/Service1';
import WhyUs1 from './WhyUs/WhyUs1';
import Footer1 from './Footer/Footer1';
import Chat from './Chat/Chat';

// Assuming dbMod and isLoading are passed as props or derived from a hook or context
const Home = ({ dbMod, isLoading }) => {

     treadmill.register() 
  return (
    <div className="App" style={{ fontFamily: 'andale mono' }}>
      {isLoading ? (
        // Display a loading indicator while data is being fetched
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <l-treadmill size="70" speed="1.25" color="black" ></l-treadmill>
        </div>
      ) : (
        // Render the main app content once data is loaded
        <div>
          <Header1 modInfo={dbMod} />
          <Cover1 modInfo={dbMod} />
          <Service1 modInfo={dbMod} />
          <WhyUs1 modInfo={dbMod} />
          <Footer1 modInfo={dbMod} />
          <div style={{ height: '150px' }}>&nbsp;</div>
          <Chat modInfo={dbMod} />
        </div>
      )}
    </div>
  );
};

export default Home;
