// emailUtils.js
import emailjs from 'emailjs-com';

export const sendData = (templateParams) => {
  return emailjs.send('service_t9bf7fo', 'template_gixvcrd', templateParams, '5KVN1XkDUCPNsF8AL')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
    
    }, (error) => {
      console.log('FAILED...', error);
      
    });
};
