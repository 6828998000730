import React from 'react';
import logo from '../logo.png';


// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Import the Instagram icon
import { faInstagram } from '@fortawesome/free-brands-svg-icons';


function Cover1({ modInfo }) {
  // Ensure the primary color is defined. If not, a fallback color is used.
  const primaryColor = modInfo.colors.primary || '#ff0000'; 

  return (
    <header id="header" className="alt" style={{
    
      color: 'black !important',
      background: `linear-gradient(to bottom right, #bcddeb, white, #bcddeb, white, #bcddeb)`, // Gradient from top left (white) to bottom right (primary color)
      textAlign: 'center',
      marginBottom: '-20px',
      paddingBottom: '50px',
    }}>
      <span className="logo">
        <img src={logo} style={{ height: '33vh' }} alt="" />
      </span>
      <h1 style={{ color: 'black', fontSize: '50px', fontWeight: 'bold' }}>In Situ Electronics</h1>
      <h1 style={{ color: 'black', fontSize: '30px' }}>IT Services, Phone & Computer Solutions</h1>
    
      <div>
        <section>
          <h2 style={{ color: 'black !important' }}>Location & Contact</h2>
          <dl className="alt">
            Address: <a href="https://maps.app.goo.gl/r27JFWvcVeUAJBiR9" target="_blank" rel="noopener noreferrer">1926 Hollywood Blvd. Ste 108, <br />Hollywood FL 33020</a><br /><br />
            Phone: <a href="tel:7864431687">786-443-1687</a><br /><br />
            Email: <a href="mailto:info@insituelectronics.com">info@insituelectronics.com</a>
          </dl>
          
              <a href="https://www.instagram.com/insituhollywood/" className="icon brands fa-instagram alt" style={{ color: 'black !important' }} target="_blank" rel="noopener noreferrer">
                <span className="label"> <FontAwesomeIcon icon={faInstagram} size="2x" /></span>
              </a>
           
        </section>
      </div>
    </header>
  );
}

export default Cover1;
