import logo from '../../logo.png';
import map from '../../map.png';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

// Importing a function to fetch data from the database
import { fetchMods } from '../../DBService';
import ChatHistory from './Dashboard/ChatHistory';
import ManageAppointments from './Dashboard/Appointments/ManageAppoinments';
import WeeklyAppointments from './Dashboard/Appointments/WeeklyAppointments';
import Appointment from './Dashboard/Appointments/Appointment';



function Dashboard({ modInfo }) {
      // State to store the current mod's data
  const [chats, setChats] = useState([]);
  
  const [appointments, setAppointments] = useState([]);
  // State to manage the loading indicator visibility
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Asynchronous function to load mod data from the database
    const loadDBMods = async () => {
      // Identify Domain Prefix
      let domainPrefix = 'in-situ-electronics';

        try {
          // Indicate that loading has started
          setIsLoading(true);
          // Fetch mod data and filter for a specific project
          const dbmodData = await fetchMods('in-situ-appointments');
         
          // Update state with the filtered project data

          setAppointments(dbmodData)
        } catch (error) {
          // Log any errors encountered during the fetch operation
          console.error('Error loading dbMods:', error);
        } finally {
          // Hide the loading indicator once data is loaded or in case of error
          setIsLoading(false);
        }
    
      try {
        // Indicate that loading has started
        setIsLoading(true);
        // Fetch mod data and filter for a specific project
        const dbmodData = await fetchMods('insituChat');
       
        // Update state with the filtered project data

        setChats(dbmodData)
      } catch (error) {
        // Log any errors encountered during the fetch operation
        console.error('Error loading dbMods:', error);
      } finally {
        // Hide the loading indicator once data is loaded or in case of error
        setIsLoading(false);
      }
    };

    // Perform the initial data load
    loadDBMods();

  }, []); // An empty dependency array ensures the effect runs only once after the initial render

  return (
    <div style={{ display: 'flex' }}>

      <ChatHistory chats={chats} />
      
    </div>
  );
}

export default Dashboard;
