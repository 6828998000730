import React, { useState, useEffect } from 'react';

// Importing a function to fetch data from the database
import { fetchMods } from './DBService';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Import context provider for authentication state
import { AuthProvider } from './AuthContext';
// Import component to protect routes that require authentication
import ProtectedRoute from './ProtectedRoute';
import Home from './PageMods/Home';
import Login from './PageMods/Auth/Login';
import Dashboard from './PageMods/Auth/Dashboard';
import SingleChatHistory from './PageMods/Chat/SingleChatHistory';



function App() {
  // State to store the current mod's data
  const [dbMod, setDBMod] = useState([]);
  // State to manage the loading indicator visibility
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Asynchronous function to load mod data from the database
    const loadDBMods = async () => {
      // Identify Domain Prefix
      let domainPrefix = 'in-situ-electronics';
      
//      const hostname = window.location.hostname;
//      // Split hostname into parts
//      const parts = hostname.split('.');
//      
//      // Assuming a standard domain format like 'subdomain.domain.tld'
//      // Adjust the logic below if your domain structure is different
//      if(parts.length > 2) {
//        // Remove the TLD and domain name to get the subdomain
//        domainPrefix = parts.slice(0, -2).join('.');
//      }

        //var fullUrl = window.location.href;

        // Parse the URL using the URL constructor
        //var url = new URL(fullUrl);

        // Get the path part of the URL (after the slash)
        //if(url.pathname === undefined) domainPrefix = url.pathname;
        
        console.log(domainPrefix);
      
      
    
      try {
        // Indicate that loading has started
        setIsLoading(true);
        // Fetch mod data and filter for a specific project
        const dbmodData = await fetchMods('landingPage');
        const project = dbmodData.find(project => project.slug === domainPrefix);
        // Update state with the filtered project data
      
        setDBMod(project);
      } catch (error) {
        // Log any errors encountered during the fetch operation
        console.error('Error loading dbMods:', error);
      } finally {
        // Hide the loading indicator once data is loaded or in case of error
        setIsLoading(false);
      }
    };

    // Perform the initial data load
    loadDBMods();

  }, []); // An empty dependency array ensures the effect runs only once after the initial render

  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Route for the login page, accessible without authentication */}
          <Route path="/login" element={<Login />} />
          {/* Protected route for the dashboard, requires authentication */}
          <Route
            path="/"
            element={
                <Home dbMod={dbMod} isLoading={isLoading} />
            }
          />
           <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard modInfo={dbMod} isLoading={isLoading} />
              </ProtectedRoute>
            }
          />
           <Route path="/insituChat/:chatId" element={<SingleChatHistory />} />
        </Routes>
      </AuthProvider>
    </Router>
   
  );
}

export default App;
