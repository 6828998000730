import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Importing a function to fetch data from the database
import { fetchMods, retrieveMod } from '../../DBService';

const SingleChatHistory = ({}) => {
  const [messages, setMessages] = useState([]);
  const historyEndRef = useRef(null);
  const { chatId } = useParams();


  // State to store the current mod's data
  const [chat, setChat] = useState([]);
  // State to manage the loading indicator visibility
  const [isLoading, setIsLoading] = useState(true);
      
  useEffect(() => {
    let isMounted = true;
    
    const loadChats = async () => {
      setIsLoading(true);
      
      try {
        
        const dbmodData = await fetchMods('insituChat'); // Changed to the correct function call
        
          console.log(dbmodData);
          for (let index = 0; index < dbmodData.length; index++) {
            if (chatId === dbmodData[index]._id.$oid) {
              
              let transformedMessages = [];
              for (let key in dbmodData[index]) {
                // Skip non-numeric keys to avoid processing metadata like "_id"
                if (!isNaN(key)) {
                  let element = dbmodData[index][key];
                  if (element && element.message) {
                    transformedMessages.push({
                      type: element.type,
                      message: element.message,
                    });
                  }
                }
              }
              
              console.log(transformedMessages);
              setMessages(transformedMessages);
            }
            
          }
          /*
          
        */
      } catch (error) {
        console.error('Error loading dbMods:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };


    loadChats();
   
  
    // Cleanup function to avoid setting state on unmounted component
    return () => {
      isMounted = false;
    };
  }, []); // Consider adding `chatId` if relevant for fetching.
  
  // Example settings, replace with your actual color scheme
  const modInfo = {
    colors: {
      primary: '#007bff', // Example primary color
    },
  };


  // Convert chat history to the proper format
  useEffect(() => {
    
  }, [chat]);

  // Scroll to the last message
  useEffect(() => {
    if (historyEndRef.current) {
      historyEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Placeholder function for summarization logic
  const handleSummarize = async () => {
    // Implement your summarization logic here
    console.log('Summarizing messages:', messages);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <h1>Chat</h1>
      <div>
        {messages.map((item, index) => (
          <div key={index} style={{ textAlign: item.type === 'sent' ? 'right' : 'left', marginBottom: '10px' }}>
            <span
              style={{
                display: 'inline-block',
                backgroundColor: item.type === 'sent' ? modInfo.colors.primary : '#f1f1f1',
                color: item.type === 'sent' ? 'white' : 'black',
                padding: '8px',
                borderRadius: '20px',
              }}
            >
              {item.message}
            </span>
          </div>
        ))}
        <div ref={historyEndRef} />
      </div>
      
    </div>
  );
};

export default SingleChatHistory;
