const TimestampFormatter = ({ timestamp }) => {
    // Create a Date object from the timestamp
    const date = new Date(timestamp);
  
    // Use Intl.DateTimeFormat to format the date and time
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    }).format(date);
  
    return <div>{formattedDate}</div>;
  };

  export default TimestampFormatter;