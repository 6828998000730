import OpenAI from 'openai';
import { sendData } from './emailUtils';
import emailjs from 'emailjs-com';
import { createMod, updateMod } from '../../DBService';


export const summarize = async (conversation) => {
  
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const assistant = await openai.beta.assistants.retrieve("asst_lme9PY4cRXZovuK3Y9bt1cI2");
  const thread = await openai.beta.threads.create();



  console.log(JSON.stringify(conversation));


  const submission = JSON.stringify(conversation);
  
  try {
    await openai.beta.threads.messages.create(thread.id, {
      role: 'user',
      content: submission,
    });

    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistant.id,
    });

    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

    while (response.status === 'in_progress' || response.status === 'queued') {
      console.log('waiting...');
      await new Promise(resolve => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    const messageList = await openai.beta.threads.messages.list(thread.id);
    const lastMessage = messageList.data
      .filter(message => message.run_id === run.id && message.role === 'assistant')
      .pop();

    // Log the last message for debugging purposes
    
    // If there is a last message, return it; otherwise, return an appropriate value or message
    if (lastMessage) {
      const regex = /```json\n([\s\S]*?)```/m;
      const match = lastMessage.content[0]['text'].value.match(regex);
      
        let data = JSON.parse(match[1]);
 
        return data; // This line has been modified to return the last message
        
      
     
    } else {
      return 'No message received from OpenAI API'; // This could be a fallback return
    }
  } catch (error) {
    console.error({ message: 'Error communicating with OpenAI API', error: error.response?.data });
  }
  

};
