import React, { useState, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import 'ldrs/ring'
import { pulsar } from 'ldrs'
import { createMod, updateMod } from '../../DBService';
import OpenAI from 'openai';
import { summarize } from '../Utilitites/chatBotUtils'; 
import { sendData } from '../Utilitites/emailUtils';

function Chat({ modInfo }) {
    const historyEndRef = useRef(null);
    
    
    const [assistant, setAssistant] = useState(null);
    const [thread, setThread] = useState(null);
    const [openai, setOpenai] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [modId, setModId] = useState(null);
    const [input, setInput] = useState('');
    const [response, setResponse] = useState('');
    const [history, setHistory] = useState([
      { 
          type: 'received', 
          message: "Welcome to In Situ Electronics Repair! 🛠️ How can I assist you today? Select a service: Screen Repair, Microsoldering, Back Glass Repair, Battery Replacement, Gaming Console Repair, Surveillance Camera Installation, Cable Management, or Sonos Installation.",
          timestamp: new Date() // Add timestamp here
      }
  ]);
  
    const [historyVisible, setHistoryVisibility] = useState(true);
    const [isVisible, setIsVisible] = useState(false); // New state to control visibility of the chat component
    
    pulsar.register();
    
    useEffect(() => {
        historyEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [history]);

    useEffect(() => {
        initChatBot();
    }, []);

    // Shrink the chat if at the bottom of the page
    useEffect(() => {
            const checkScrollBottom = () => {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;
            
            // If the user is at the bottom of the page, hide the chat component
            if (scrollTop + clientHeight >= scrollHeight - 30) {
                setIsVisible(false);
                }
            };
            
            // Add scroll event listener when component mounts
            window.addEventListener('scroll', checkScrollBottom);
            
            // Remove event listener on cleanup to prevent memory leaks
            return () => window.removeEventListener('scroll', checkScrollBottom);
    }, []);


        const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    function changeHistoryVisibility() {
        setIsVisible(!isVisible);
    }

    const initChatBot = async () => {
        const openaiInstance = new OpenAI({
            apiKey: process.env.REACT_APP_OPENAI_API_KEY,
            dangerouslyAllowBrowser: true,
        });

    const assistantInstance = await openaiInstance.beta.assistants.retrieve(
        "asst_0h7XT4MbNFinxf7MhOQcqJTu"
    );

    const threadInstance = await openaiInstance.beta.threads.create();

    setOpenai(openaiInstance);
    setAssistant(assistantInstance);
    setThread(threadInstance);
  };


const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    const submission = input; // Capture input before sending
   setWaiting(true);


    try {
        await openai.beta.threads.messages.create(thread.id, {
            role: 'user',
            content: input,
          });
      
          const run = await openai.beta.threads.runs.create(thread.id, {
            assistant_id: assistant.id,
          });
      
          let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
      
          while (response.status === 'in_progress' || response.status === 'queued') {
            console.log('waiting...');
            console.log();
            await new Promise(resolve => setTimeout(resolve, 10000));
            response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
          }
      
         
      
          const messageList = await openai.beta.threads.messages.list(thread.id);
      
          const lastMessage = messageList.data
            .filter(message => message.run_id === run.id && message.role === 'assistant')
            .pop();
      
          if (lastMessage) {
           
            console.log({...history});
            setResponse(lastMessage.content[0]['text'].value);
            setHistory([...history, 
              { type: 'sent', message: submission, timestamp: new Date() }, // Add timestamp here
              { type: 'received', message: lastMessage.content[0]['text'].value, timestamp: new Date() } // And here
            ]);
          
            if (lastMessage.content[0]['text'].value.indexOf('🛠️') > -1) {
              
              
              //const modId = await createMod(history, 'insituChat');
                       
              
              //summarize(history);
              
              //setWaiting(false);
                
            }
            else {
            }


            if (modId) {
              await updateMod(modId, {
                type: 'received', message: lastMessage.content[0]['text'].value, timestamp: new Date() 
               }, history.length + 1, 'insituChat')
              await updateMod(modId, {
                type: 'sent', message: input, timestamp: new Date() 
               }, history.length, 'insituChat')
              

             

            }
            else {
              let data = {...[
                ...history, { type: 'sent', message: input, timestamp: new Date()}, { type: 'received', message: lastMessage.content[0]['text'].value, timestamp: new Date() }
              ]};
              data.timestamp = new Date();
              const newModId = await createMod(data, 'insituChat');
                setModId(newModId.insertedId.$oid); 

                sendData({slug: 'https://www.insituelectronics.com/dashboard'});
               

            }
            
            setWaiting(false);
            
          }
      
    } catch (error) {
      console.error({message: 'Error communicating with OpenAI API', error: error.response?.data});
    }
    setInput(''); // Clear input after sending
  };
  if (!isVisible) {
    return (
      <div style={{ position: 'fixed', bottom: '10px', left: '10px', maxWidth: '300px', border: '5px solid' + modInfo.colors.secondary, padding: '20px', borderRadius: '0 20px', backgroundColor: 'white', boxSizing: 'border-box' }}>
             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h2 style={{ color: modInfo.colors.primary }}>Chat with Us&nbsp;&nbsp;</h2>
        <button
          onClick={changeHistoryVisibility}
          style={{
          
            width: '6em',
            height: '40px',
            borderRadius: '15px',
            border: '0',
            fontSize: '1em',
            background: modInfo.colors.secondary,
            color: 'white',
            fontWeight: 'normal'
          }}
          type="button"
        >
          { 'Show'} <FontAwesomeIcon icon={faArrowUp} />
        </button>
      </div>
      </div>
    );
  }

  return (
    <div style={{ position: 'fixed', bottom: '10px', left: '10px',  maxWidth: '300px', border: '5px solid' + modInfo.colors.secondary, padding: '20px', borderRadius: '0 20px', backgroundColor: 'white', boxSizing: 'border-box' }}>
      <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
        <h2 style={{ color: modInfo.colors.primary }}>Chat with Us</h2>
        <button
          onClick={changeHistoryVisibility}
          style={{
            
            width: '6em',
            height: '40px',
            borderRadius: '15px',
            border: '0',
            fontSize: '1em',
            background: modInfo.colors.secondary,
            color: 'white',
            fontWeight: 'normal'
          }}
          type="button"
        >
          {historyVisible ? 'Hide' : 'Show'} <FontAwesomeIcon icon={historyVisible ? faArrowDown : faArrowUp} />
        </button>
      </div>

      <div style={{ maxHeight: '300px', overflowY: 'auto', display: historyVisible ? 'block' : 'none' }}>
        {history.map((item, index) => (
          <div key={index} style={{ textAlign: item.type === 'sent' ? 'right' : 'left', marginBottom: '10px' }}>
            <span style={{
              display: 'inline-block',
              backgroundColor: item.type === 'sent' ? modInfo.colors.primary : '#f1f1f1',
              color: item.type === 'sent' ? 'white' : 'black',
              padding: '8px',
              borderRadius: '20px',
            }}>
              {item.message}
            </span>
            <div ref={historyEndRef} />
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
        {
            !waiting ? 
            (
                <>
                <input
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  style={{ width: 'calc(100% - 8em)', marginRight: '10px', padding: '10px', borderRadius: '20px', border: '1px solid #ccc' }}
                />
                <button
          style={{
            width: '6em',
            padding: '10px',
            borderRadius: '15px',
            border: '0',
            fontSize: '1em',
            background: modInfo.colors.primary,
            color: 'white',
            fontWeight: 'normal'
          }}
          type="submit"
        >
          Send
        </button>
        </>
            ) :
            // Default values shown
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <l-pulsar
              size="35"
              stroke="3.5"
              speed="1" 
              color={modInfo.colors.secondary}
              ></l-pulsar>
            </div>
        }
        
      </form>
    </div>
  );

}

export default Chat;