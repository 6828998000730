import React from 'react';
import TimestampFormatter from './Appointments/TimestampFormatter';

const Conversation = ({ conversation, historyVisible, modInfo, conversationIndex, chats }) => {
  if (!conversation) {
    return <p>Loading...</p>
  }

  return (
    <div>
      <div style={{ maxHeight: '300px', overflowY: 'auto', display: historyVisible ? 'block' : 'none' }}>
        <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
          <div>
            <h2>Conversation {chats.length - conversationIndex} </h2>
            
            <h3><TimestampFormatter timestamp={chats[conversationIndex].timestamp} /></h3>

          </div>
         
        </div>
        {conversation && conversation.map((item, messageIndex) => (
          <div key={messageIndex} style={{ textAlign: item.type === 'sent' ? 'right' : 'left', marginBottom: '10px' }}>
            <span style={{
              display: 'inline-block',
              backgroundColor: item.type === 'sent' ? modInfo.colors.primary : '#f1f1f1',
              color: item.type === 'sent' ? 'white' : 'black',
              padding: '8px',
              borderRadius: '20px',
            }}>
              {item.message}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Conversation;
